var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"titulo":"Crear servicio de paquetería","tamano":"modal-md","adicional":"Crear","no-aceptar":""},on:{"adicional":_vm.handleCreate}},[_c('ValidationObserver',{ref:"validacion",staticClass:"row justify-content-center"},[_c('ValidationProvider',{staticClass:"col-10 mb-3",attrs:{"rules":"required|max:60","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 pl-2"},[_vm._v("Nombre")]),_c('el-input',{attrs:{"placeholder":"Nombre del servicio","size":"small"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-10 mb-3",attrs:{"rules":"required|max:120","name":"dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 pl-2"},[_vm._v("Token")]),_c('el-input',{attrs:{"placeholder":"Número","size":"small"},model:{value:(_vm.model.token),callback:function ($$v) {_vm.$set(_vm.model, "token", $$v)},expression:"model.token"}}),_c('span',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-10 mb-3",attrs:{"rules":"required|max:200","name":"descripcion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 pl-2"},[_vm._v("Descripción")]),_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 0, maxRows: 3 },"placeholder":"Añade una descripción"},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}}),_c('span',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"col-10",attrs:{"rules":"","name":"cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"f-12 pl-2"},[_vm._v("Seleccionar cedis")]),_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Selecciona","size":"small"},on:{"change":_vm.addCedis},model:{value:(_vm.idCedis),callback:function ($$v) {_vm.idCedis=$$v},expression:"idCedis"}},_vm._l((_vm.selectCedis),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id,"disabled":item.disabled}})}),1),_c('span',{staticClass:"text-danger f-10"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"col-10"},_vm._l((_vm.idsCedis),function(data,index){return _c('div',{key:index,staticClass:"d-middle border br-5 my-2 py-1 px-2 f-14 fr-light bg-whitesmoke4"},[_c('p',[_vm._v(" "+_vm._s(data.nombre))]),_c('i',{staticClass:"icon-close ml-auto cr-pointer",on:{"click":function($event){return _vm.removerCedis(data.id)}}})])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }