<template>
	<modal ref="modal" titulo="Crear servicio de paquetería" tamano="modal-md" adicional="Crear" @adicional="handleCreate"
		no-aceptar>
		<ValidationObserver ref="validacion" class="row justify-content-center">
			<ValidationProvider v-slot="{ errors }" rules="required|max:60" name="nombre" class="col-10 mb-3">
				<p class="f-12 pl-2">Nombre</p>
				<el-input v-model="model.nombre" placeholder="Nombre del servicio" size="small" />
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
			<ValidationProvider v-slot="{ errors }" rules="required|max:120" name="dirección" class="col-10 mb-3">
				<p class="f-12 pl-2">Token</p>
				<el-input v-model="model.token" placeholder="Número" size="small" />
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
			<ValidationProvider v-slot="{ errors }" rules="required|max:200" name="descripcion" class="col-10 mb-3">
				<p class="f-12 pl-2">Descripción</p>
				<el-input v-model="model.descripcion" type="textarea" :autosize="{ minRows: 0, maxRows: 3 }"
					placeholder="Añade una descripción" />
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
			<ValidationProvider v-slot="{ errors }" rules="" name="cedis" class="col-10">
				<p class="f-12 pl-2">Seleccionar cedis</p>
				<el-select v-model="idCedis" placeholder="Selecciona" size="small" class="w-100" @change="addCedis">
					<el-option v-for="item in selectCedis" :key="item.id" :label="item.nombre" :value="item.id" :disabled="item.disabled" />
				</el-select>
				<span class="text-danger f-10">{{ errors[0] }}</span>
			</ValidationProvider>
			<div class="col-10">
				<div v-for="(data, index) in idsCedis" :key="index"
					class="d-middle border br-5 my-2 py-1 px-2 f-14 fr-light bg-whitesmoke4">
					<p> {{ data.nombre }}</p>
					<i class="icon-close ml-auto cr-pointer" @click="removerCedis(data.id)" />
				</div>
			</div>
		</ValidationObserver>
	</modal>
</template>

<script>
import { emit } from "process";
import Servicio from "~/services/serviciosPaqueteria";
export default {
	data() {
		return {
			model: {
				nombre: '',
				token: '',
				descripcion: '',
			},
			idsCedis: [],
			idCedis: ''
		}
	},
	computed: {
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0)
		}
	},
	methods: {
		async toggle() {
			this.selectCedis.map( e => e.disabled = false)
			this.idsCedis = [];
			this.model= {
				nombre: '',
				token: '',
				descripcion: '',
			},
			this.$refs.modal.toggle()
		},
		addCedis(id){
			let cedis = this.selectCedis.find( (e)=>e.id === id)
			cedis.disabled = true
			this.idsCedis.push(cedis)
			this.idCedis = ''
		},
		removerCedis(id){
			try {
				this.selectCedis.find(o=>o.id === id).disabled = false;
				const idx = this.idsCedis.findIndex( (e) => e.id == id)
				this.idsCedis.splice(idx, 1)
			} catch (e) {
				return this.error_catch(e)
			}
        },
		async handleCreate() {
			try {
				const valid = await this.$refs.validacion.validate()
				if(!valid) return

				this.model.idsCedis = this.idsCedis.map(element => element.id);

				const { data } = await Servicio.postServicio(this.model);
				this.notificacion('', data.mensaje, "success")
				this.$emit('update', data.data)
				this.$refs.modal.toggle()
			} catch (e) {
				return this.error_catch(e)
			}
		}
	}
}
</script>

<style lang="css" scoped></style>
